export default {
	scales: {
		x: {
			ticks: {
				callback: function(val, index) {
					return index % 2 === 0 ? this.getLabelForValue(val) : '';
				}
			},
		},
		y: {
			ticks: {
				display: true,
				callback: (value, index, ticks) => {
					return Number(value).toLocaleString() + ' tweets';
				}
			},
			title: {
				display: false,
				text: 'Tweet count',
				color: '#333',
				font: {
					weight: 'bold',
				},
			},
		}
	},
	plugins: {
		tooltip: {
			callbacks: {
				label: (context) => {
					//console.log(context);
					let label = context.dataset.label || '';
					if (label) {
						label += ': ';
					}
					if (context.parsed.y !== null) {
						label += context.formattedValue + ' tweets';
						//label += context.parsed.y;
					}
					return label;
				}
			},
		},
		legend: {
			position: 'bottom',
			labels: {
				padding: 30,
			}
		},
		title: {
			display: true,
			text: 'Tweet counts',
			padding: 30,
			font: {
				weight: 'bold',
				size: 18,
			},
		},
	},
	elements: {
		point: {
			radius: 5,
			pointStyle: 'circle',
		},
		line: {
			tension: 0,
			borderJoinStyle: 'round',
			borderWidth: 6,
		}
	}
}