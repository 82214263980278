import React, { useContext, useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import Hosts from 'Hosts'

const host = `${Hosts.API_PROTOCOL}://${Hosts.API_HOST}:${Hosts.API_PORT}`;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useFetch = (url, setCategories) => {
	useEffect(async () => {
		let toDate = getFromLocalStorage('toDateState', 'date');	
		let fromDate = getFromLocalStorage('fromDateState', 'date');	
		let response = await axios.post(url, {start_time:fromDate.toISOString(), end_time: toDate.toISOString()});
		let newData = ['All', ...response.data];
		setInLocalStorage('categories', newData, 'json');
		setCategories(newData);
	}, [url]);
}

const useFetchHashTags = (url, setHashtags, category) => {
	useEffect(async () => {
		if (category !== "") {
			let toDate = getFromLocalStorage('toDateState', 'date');	
			let fromDate = getFromLocalStorage('fromDateState', 'date');	
			let response = await axios.post(url, {category: category,start_time:fromDate.toISOString(), end_time: toDate.toISOString()});
			setHashtags(response.data);
		}
	}, [url]);
}

const setInLocalStorage = (key, val, datatype) => {
	let newVal;
	switch(datatype) {
		case "date":
			newVal = val.toISOString();
			break;
		case "json":
			newVal = JSON.stringify(val);
			break;
		case "bool":
			newVal = val == 'true';
			break;
		default:
			newVal = val;
	}
	window.localStorage.setItem(key, newVal);
}

const getFromLocalStorage = (key, datatype) => {
	if (window.localStorage.getItem(key) == null) {
		return null
	}
	let temp = window.localStorage.getItem(key);
	let newVal;
	switch(datatype) {
		case "date":
			newVal = new Date(temp);
			break;
		case "json":
			newVal = JSON.parse(temp);
			break;
		case "bool":
			newVal = temp == 'true';
			break;
		default:
			newVal = temp;
	}
	return newVal;
}

const MainMenu = (props) => {

	// from and to date
	let toDate = getFromLocalStorage('toDateState', 'date');
	if (toDate === null) {
		toDate = new Date();
		toDate.setMinutes(0);
		toDate.setSeconds(0);
		toDate.setMilliseconds(0);
		setInLocalStorage('toDateState', toDate, 'date');
	}
	let fromDate = getFromLocalStorage('fromDateState', 'date');
	if (fromDate === null) {
		fromDate = new Date();
		fromDate.setMinutes(0);
		fromDate.setSeconds(0);
		fromDate.setMilliseconds(0);
		fromDate.setHours(toDate.getHours()-24);
		setInLocalStorage('fromDateState', fromDate, 'date');
	}
	const [ dateRangeValue, setDateRangeValue ] = useState([fromDate, toDate]);
	
	// categories
	let categories_ls = getFromLocalStorage('categories', 'json');
	let chosencat_ls = getFromLocalStorage('category', 'string');
	if (categories_ls === null) {
		categories_ls = [];
		chosencat_ls = '';
		setInLocalStorage('categories', categories_ls, 'json');
		setInLocalStorage('category', chosencat_ls, 'string');
	}
	const [ categories, setCategories ] = useState(categories_ls);
	const [ category, setCategory ] = useState(chosencat_ls);

	// hashtags
	let hashtags_ls = getFromLocalStorage('hashtagsState', 'json');
	if (hashtags_ls === null) {
		hashtags_ls = [];
		setInLocalStorage('hashtagsState', hashtags_ls, 'json');
	}
	const [ hashtags, setHashtags ] = useState(hashtags_ls);

	// selections
	let selection_ls = getFromLocalStorage('selectionState','json');
	if (selection_ls === null) {
		selection_ls = [];
		setInLocalStorage('selectionState', selection_ls, 'json');
	}
	const [ selection, setSelection ] = useState(selection_ls);
	
	// tweet options
	let excludeRT_ls = getFromLocalStorage('excludeRTState', 'bool');
	if (excludeRT_ls === null) {
		excludeRT_ls = false;
		setInLocalStorage('excludeRTState', excludeRT_ls, 'bool');
	}
	const [ excludeRetweet, setExcludeRetweet ] = useState(excludeRT_ls);

	const fetchDataProxy = () => {
		props.fetchData(dateRangeValue[0], dateRangeValue[1], excludeRetweet, selection, props.setRemoteChartData, props.chartType);
	}

	const retweetCheckboxChange = (e) => {
		let newValue = e.target.checked;
		setInLocalStorage('excludeRTState', newValue)
		setExcludeRetweet(newValue);
	}

	const selectChange = async (e) => {
		const {
			target: {value},
		} = e;
		let selections = typeof value === 'string' ? value.split(',') : value;
		setInLocalStorage('selectionState', selections, 'json');
		setSelection( selections );
		let toDate = getFromLocalStorage('toDateState', 'date');	
		let fromDate = getFromLocalStorage('fromDateState', 'date');
		let url = `${host}/hashtags`;
		let category = getFromLocalStorage('category', 'string');
		let response = await axios.post(url, {category: category, start_time: fromDate.toISOString(), end_time: toDate.toISOString() });		
		setHashtags(response.data);
	}

	const categoryChange = async (e) => {
		let toDate = getFromLocalStorage('toDateState', 'date');	
		let fromDate = getFromLocalStorage('fromDateState', 'date');	
		setInLocalStorage('category', e.target.value, 'string');
		setCategory(e.target.value);
		let url = `${host}/hashtags`;
		let response = await axios.post(url, {category: e.target.value, start_time: fromDate.toISOString(), end_time: toDate.toISOString()});
		setInLocalStorage('selectionState', [], 'json');
		setSelection( [] );
		setHashtags(response.data);

	}

	useFetch(`${host}/categories`, setCategories);
	useFetchHashTags(`${host}/hashtags`, setHashtags, category);

	return (
		<Grid item xs={props.columnSize}>
			<Grid container spacing={2}>
				<Grid item>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DateRangePicker
							startText="From Date"
							endText="To Date"
							onChange={(newValue) => {
								let from = newValue[0];
								if (from !== null) {
									from.setMinutes(0);
									from.setSeconds(0);
									from.setMilliseconds(0);
									setInLocalStorage('fromDateState', from, 'date');
								}
								let to = newValue[1];
								if (to !== null) {
									to.setMinutes(0);
									to.setSeconds(0);
									to.setMilliseconds(0);
									setInLocalStorage('toDateState', to, 'date');
									setDateRangeValue(newValue);
								}
							}}
							renderInput={(startProps, endProps) => (
								<React.Fragment>
									<TextField {...startProps} size="small" sx={{width: '104px'}} />
									<Box sm={{ mx:2 }} sx={{margin:'5px',}}>to</Box>
									<TextField {...endProps} size="small" sx={{width: '104px'}} />
								</React.Fragment>
							)}
							value={dateRangeValue}
						/>
					</LocalizationProvider>
				</Grid>
				<Grid item>
					<FormGroup>
						<FormControlLabel checked={excludeRetweet} control={<Checkbox onChange={retweetCheckboxChange} />} label="Exclude Retweet" />
					</FormGroup>
				</Grid>
				<Grid item>
					<FormControl sx={{width: 260}}>
						<InputLabel id="category-label">Categories</InputLabel>
						<Select
							labelId="category-label"
							id="category-choice"
							value={category}
							onChange={categoryChange}
							label="Categories"
						>
							{categories.map((r) => (
								<MenuItem key={r} value={r} sx={{paddingLeft: '10px', paddingRight: '0'}}>
									<ListItemText primary={r} />
								</MenuItem>
							))}
						</Select>
					</FormControl>
					{hashtags.length === 0 ? null : 
					(<FormControl sx={{width: 260, marginTop: '8px'}}>
						<InputLabel id="hashtags-label">Hashtags</InputLabel>
						<Select
							labelId="hashtags-label"
							id="hashtags-checkboxes"
							multiple
							value={selection}
							onChange={selectChange}
							input={<OutlinedInput label="Hashtag" />}
							renderValue={(selected) => selected.join(', ')}
							MenuProps={MenuProps}
						>
							{hashtags.map((h) => (
								<MenuItem key={h} value={h} sx={{paddingLeft:'0',paddingRight:'0',}}>
									<Checkbox checked={selection.indexOf(h) > -1} />
									<ListItemText primary={h} />
								</MenuItem>
							))}
						</Select>
					</FormControl>)
					}
				</Grid>
				<Grid item>
					<Button variant="contained" onClick={fetchDataProxy}>Update</Button>
				</Grid>
				<Grid item>
					<Box>
						<Typography variant="caption" display="block" gutterBottom>
							<strong>NOTE:</strong> From and To Date & Time are your local time. However, the time shown on the graph is Los Angeles Time.
						</Typography>
					</Box>
				</Grid>
			</Grid>
		</Grid>
	)

}


export default MainMenu;