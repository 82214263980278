import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "../style/main.scss";

const theme = createTheme({
	typography: {
		fontFamily: [
			'Roboto Condensed',
			'sans-serif'
		].join(','),
	},
})


ReactDOM.render((
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<CssBaseline />
			<Container maxWidth="xl">
				<App />
			</Container>
		</BrowserRouter>
	</ThemeProvider>
	), 
	document.getElementById("root")); 