import React, { useContext, useEffect, useRef, useState } from 'react';
import { 
	Button,
	Menu,
	MenuItem,
} from '@mui/material';
import { Link } from "react-router-dom";

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useNavigate } from 'react-router-dom';

const NavMenu = (props) => {

	let navigate = useNavigate();

	const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

	const loadChart = (chartType) => {
		setAnchorEl(null);
		switch (chartType) {
			case 'line':
				navigate('/');
				break;
			case 'pie':
				navigate('/piechart');
				break;
			case 'bar':
				navigate('/barchart');
				break;
		}
		// TODO. make fetching data work upon switching chart type
		// props.fetchData(chartType, props.setRemoteChartData);
	}

	return (
		<React.Fragment>
			<Button
				color="secondary"
				id="chart-types-btn"
				aria-controls={open ? 'chart-types-menu' : undefined}
				aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        disableElevation
        onClick={handleClick}
				endIcon={<KeyboardArrowDownIcon />}
			>
				Chart Types
			</Button>
			<Menu 
					id="chart-types-menu"
					MenuListProps={{'aria-labelledby': 'chart-types-btn'}}
					anchorEl={anchorEl}
					open={open}
					onClose={handleClose}
			>
				<MenuItem onClick={() => {loadChart('line')}} disableRipple>
					<SsidChartIcon />
					Line Chart
				</MenuItem>
				<MenuItem onClick={() => {loadChart('pie')}} disableRipple>
					<PieChartIcon />
					Pie Chart
				</MenuItem>
				<MenuItem onClick={() => {loadChart('bar')}} disableRipple>
					<BarChartIcon />
					Bar Chart
				</MenuItem>
			</Menu>

		</React.Fragment>
	)
}

export default NavMenu;