import React, { useState } from 'react';
import { Routes, Route,} from 'react-router-dom';
import Dashboard from '../Dashboard/index.jsx';
import PieChart from '../Dashboard/pieChart.jsx';
import BarChart from '../Dashboard/barChart.jsx'
import Hosts from 'Hosts'
import axios from 'axios';

const host = `${Hosts.API_PROTOCOL}://${Hosts.API_HOST}:${Hosts.API_PORT}`;

const AppRoute = (props) => {

	const [reqData, setReqData] = useState({});

	const fetchData = async function (start, end, retweet, selection, setRemoteChartData, chartType) {
		let body = {}
		let switchChartTypeParam;
		let setData;
		// this is a dirty trick... lol
		if (arguments.length === 2) {
			body = { ...reqData };
			switchChartTypeParam = arguments[0];
			setData = arguments[1];
		} else {
			body.start = start.toISOString();
			body.end = new Date(end.toISOString());
			body.end.setHours(24);
			body.end = body.end.toISOString();
			// exclude retweet sentiment
			body.option = '' + (retweet ? 'exclude retweet' : '');
			body.option = body.option.trim();
			body.hashtags = selection;
			setReqData(body);
			switchChartTypeParam = chartType;
			setData = setRemoteChartData;
		}
	

		let url = "";
		switch (switchChartTypeParam) {
			case 'line':
				url = `${host}/trends`;
				break;
			case 'pie':
				url = `${host}/trendspie`;
				break;
			case 'bar':
				url = `${host}/trendspie`;
				break;
			default:
				url = `${host}/trends`;
		}
		let resp = await axios.post(url, body);
		setData(resp.data);
	}

	return (
		<Routes>
			<Route exact path="/" element={<Dashboard fetchData={fetchData} />}  />
			<Route exact path="/piechart" element={<PieChart fetchData={fetchData} />}  />
			<Route exact path="/barchart" element={<BarChart fetchData={fetchData} />} />
		</Routes>
	);
}

export default AppRoute;