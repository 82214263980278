import React, { useContext, useEffect, useRef, useState } from 'react';
import AppBar from '@mui/material/AppBar'
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import chartOptions from './lineChartOptions'
import MainMenu from '../../component/MainMenu'
import NavMenu from '../../component/NavMenu'

import {
	registerables,
} from 'chart.js'
import 'chartjs-adapter-date-fns';
import { ReactChart } from 'chartjs-react';

ReactChart.register(...registerables);

const COLORS = [
	'rgb(220, 20, 60)',
	'rgb(255, 20, 147)',
	'rgb(255, 140, 0)',
	'rgb(255, 215, 0)',
	'rgb(186, 85, 211)',
	'rgb(148, 0, 211)',
	'rgb(139, 0, 139)',
	'rgb(46, 139, 87)',
	'rgb(154, 205, 50)',
	'rgb(0, 128, 128)',
	'rgb(70, 130, 180)',
	'#DEE2FF',
	'#5a189a',
	'#4d194d',
	'#a1ff0a',
]

const convertToChartData = (respData) => {
	let datasets = [];
	
	// dataset
	let count = 0;
	for (let key in respData.data) {
		let data = {}
		data.label = key;
		data.borderColor = COLORS[count];
		data.backgroundColor = COLORS[count];	
		data.data = respData.data[key];
		datasets.push(data);
		count++;
	}
	return { labels: [...respData.labels], datasets: datasets };
}

const Dashboard = (props) => {

	const [ remoteChartData, setRemoteChartData ] = useState({data:{},labels:[]});
	
	const LineChart = (data) => {
		return (<ReactChart
			type="line"
			data={data}
			options={chartOptions}
			height={200} />)
	}

	return (
		<React.Fragment>
			<AppBar position="fixed" color="secondary" enableColorOnDark sx={{
				boxShadow: 2,
				minHeight: 70,
				display: 'flex',
				alignItem: 'center',
				justifyContent: 'center',

			}}>
				<Typography variant="h4" component="h4" sx={{textAlign:'center',}}>
					Bangtan Trends : Line Chart
				</Typography>
			</AppBar>

			<Box sx={{display:'flex', flexDirection: 'row-reverse', marginTop:'90px', marginBottom:'30px'}} >
				<NavMenu fetchData={props.fetchData} setRemoteChartData={setRemoteChartData} />
			</Box>
			<Divider />
			<Grid container spacing={2} columns={16} sx={{ marginTop:'10px', }}>
				<MainMenu fetchData={props.fetchData} setRemoteChartData={setRemoteChartData}fetchData={props.fetchData} setRemoteChartData={setRemoteChartData} columnSize={4} chartType="line" />
				<Grid item xs={12}>
					{LineChart(convertToChartData(remoteChartData))}
				</Grid>
			</Grid>
		</React.Fragment>
	);	
}

export default Dashboard;