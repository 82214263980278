export default {
	datasets: {
		doughnut: {
			radius: '80%',
			cutout: '20%',
			borderColor: 'rgba(75,75,75,0.4)',
			borderJoinStyle: 'bevel',
			borderWidth: 1,
			spacing: 0,
			borderAlign: 'inner',
		},
	}
}