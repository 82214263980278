import React, { Component, useState } from 'react'
import AppRoute from './containers/AppRoute/index'
// import { FormDataProvider } from './context/UserContext'

export default function App() {

  return (
		<div className="root">
			<AppRoute />  
		</div>
  );

}

